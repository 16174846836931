import React from "react"
import { Box } from "@chakra-ui/core"

type Props = {}

export const TextContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box
      className="markdownText"
      p={[2, 4, 4, 4]}
      marginX="auto"
      width={[
        "95%", // base
        "90%", // 480px upwards
        "75%", // 768px upwards
        "60%", // 992px upwards
      ]}
    >
      {children}
    </Box>
  )
}
